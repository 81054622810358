* {
  box-sizing: border-box;
  font-family: "Rubik", "sans-serif";
  margin: 0;
  padding: 0;
}

#strong {
  font-family: "Lobster";
}

footer {
  background-color: lightgray;
  padding: 10px;
}